<template>
  <div
    class="absolute BG0 p-6 rounded-lg 2xl:right-40 top-[50px] shadow-md z-50 w-fit"
  >
    <h3 class="H300 N900 mb-2">Apps</h3>
    <div class="grid grid-cols-4 gap-2 cursor-pointer">
      <div
        v-for="(app, index) in displayedApps"
        :key="index"
        :text="app.text"
        class="w-[90px] h-auto rounded-lg group hover:bg-[#FFF0EA] p-2"
      >
        <a
          href="javascript:void(0)"
          @click="routeUrl(app.link)"
          class="group flex flex-col justify-center items-center"
        >
          <component :is="app.icon" class="group-hover:stroke-[#DD5928]" />
          <span
            class="N700 P100 text-center group-hover:text-[#DD5928]"
            style="white-space: pre-line"
            >{{ app.text }}</span
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import config from '@/config/config';
import PerformanceSvg from "../assets/menu-icons/PerformanceSvg.vue";
import ResourceSvg from "../assets/menu-icons/ResourceSvg.vue";
import EmployeeManagerSvg from "../assets/menu-icons/EmployeeManagerSvg.vue";
import ContractSvg from "../assets/menu-icons/ContractSvg.vue";
import ClockITSvg from "../assets/menu-icons/ClockITSvg.vue";
import PayrollSvg from "../assets/menu-icons/PayrollSvg.vue";
import InvoiceSvg from "../assets/menu-icons/InvoiceSvg.vue";
import ProcurementSvg from "../assets/menu-icons/ProcurementSvg.vue";
import LeaveSvg from "../assets/menu-icons/LeaveSvg.vue";
import IncidenceManagerSvg from "../assets/menu-icons/IncidenceManagerSvg.vue";
import MeetingRoomBookingSvg from "../assets/menu-icons/MeetingRoomBookingSvg.vue";
import { getToken } from "@/services/auth";
import { useStore } from "@/store";
import { computed } from "vue";
import {AppType} from "../types/AppsNavypes";

const user = useStore()?.user;
const store = useStore()
const permitedApps = user?.apps;


const apps: AppType[] = [
  { text: "Performance manager", link: config.performanceLink, icon: PerformanceSvg, key:"performance management" },
  { text: "Resource manager", link: config.resourceLink, icon: ResourceSvg, key:"resource management" },
  { text: "Employee manager", link: config.employeeManagerLink, icon: EmployeeManagerSvg, key:"employee management" },
  { text: "Contract manager", link: config.contractManagerLink, icon: ContractSvg, key:"contract management" },
  { text: "Clock-IT", link: config.clockItLink, icon: ClockITSvg, key:"time tracker" },
  { text: "Payroll", link: config.payrollLink, icon: PayrollSvg, key:"payroll" },
  { text: "Invoice manager", link: config.invoiceManagerLink, icon: InvoiceSvg, key:"invoice manager" },
  { text: "Procurement manager", link: config.procurementManagerLink, icon: ProcurementSvg, key:"procurement manager" },
  { text: "Leave manager", link: config.leaveManagerLink, icon: LeaveSvg, key:"leave management" },
  // { text: "Loan manager", link: config.loansManagerLink, icon: PayrollSvg, key:"loans" },
  { text: "Incidence manager", link: config.incidenceManagerLink, icon: IncidenceManagerSvg, key:"incidence manager" },
  { text: "Meeting room booking", link: config.meetingRoomBookingLink, icon: MeetingRoomBookingSvg, key:"meeting booking" },
];
const routeUrl = (link:string="") =>{
  const token: string|null = getToken();
  if(token){
window.location.href = `${link}?app-token=${token}&login-hint=${store.usersHint}`;
   
  }
}


const displayedApps = computed(() => {
  return apps.filter(app => permitedApps.includes(app.key)).sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
})
</script>