<template>
  <div
    class="
      absolute
      top-[3.0rem] 
      lg:right-2
      sm:right-14
      bg-white
      w-fit
      pl-3
      pr-12
      py-2.5
      shadow-md
      z-50
      rounded
      N800
      P200
    "
  >
    <a class="flex gap-2 mb-4 cursor-pointer" @click.prevent="viewEmployee()">
      <img src="@/assets/profile.svg" class="self-center" alt="profile" />
      <p>My profile</p>
    </a>
    <a
      class="flex gap-2"
      href="javascript:void(0);"
      @click.prevent="userLogout()"
    >
      <img src="@/assets/logout.svg" alt="logout" />
      <p>Logout</p>
    </a>
  </div>
</template>
<script setup lang="ts">
import { getToken, logout } from "@/services/auth";
import { useAccessLevelStore } from "@/apps/loan-service/Loans-store/store_access_level";
import {useLoansPermissionsStore} from "@/apps/loan-service/Loans-store/store_loans_permissions";
import { useStore } from "@/store";
import { encodeUrlPath } from "@/helpers/utils";

const access = useAccessLevelStore();
const accesslevel = useLoansPermissionsStore();
const store = useStore();
const env = process.env;
const loginHint: string = store.usersHint;

const viewEmployee = () => {
  let encryptedId = encodeUrlPath(store?.user?.user_id?.toString());
  let hostedURL = env.VUE_APP_EMPLOYEE_URL;
  const token: string|null = getToken();
  location.href = `${hostedURL}view-employee/${encryptedId}/personal?app-token=${token}&login-hint=${loginHint}`;
};


const userLogout = () => {
   logout().then(() => {
    localStorage.removeItem("app-token");
    window.location.href = `${env.VUE_APP_SSO}/logout?login-hint=${store?.usersHint}&account=${store?.user?.email}&redirect-url=${env.VUE_APP_BASE}/login`;
  });

  access.$reset();
  accesslevel.$reset();
};
</script>
