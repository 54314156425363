import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
 import PageNotFound from "../apps/PageNotFound.vue";
import loanRoutes from "../apps/loan-service/router/loanRoutes";
import { getToken, getUserid, isLoggedIn, setToken } from "../services/auth";
import { decodeRouteToken, encodeUrlPath } from "@/helpers/utils";
import { useStore } from "@/store";


const route: Array<RouteRecordRaw> = [
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ "../apps/authentication/ChangePassword.vue"
      ),
  },
{
    path: "/login",
    name: "login",
    alias: "/login",
    component: () => {
      window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}login`;
      return null; // Adjust the import path accordingly
    },
    meta: { auth: false }
  },
  {
    path: "/myglobalcomponentsarehere",
    name: "ShowGlobal",
    component: () =>
      import(/* webpackChunkName: "global" */ "@/ui-kit/GlobalComponents.vue")
  },
  {
    path: "/legacy",
    name: "legacy",
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/apps/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    }
  },
  {
    path: "/",
    alias: "/loans",
    name: "loans",
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "../apps/loan-service/views/LoanRequestOverview.vue"
      )
  },
  {
    path: "/learnmore",
    name: "Learn More",
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "config" */ "@/components/LearnMore.vue")
  },
  {
    path: "/user-profile",
    name: "user-profile",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`)
  },
  {
    path: "/request-loan",
    name: "request-loan",
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal/loans`)
  },

  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const env = process.env;
const isLoanRoutesEnabled = !env.VUE_APP_LOAN_ROUTES_ENABLED;

export const routes = route.concat(isLoanRoutesEnabled ? loanRoutes : []);


// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});


  router.beforeEach((to, _from, next) => {
      if(to.query["app-token"]){
          const appToken = to.query["app-token"];
          const decodedToken = decodeRouteToken(appToken as string);
          const loginHint = to.query["login-hint"] as string;
          setToken(appToken as string);
          useStore()?.setUser(decodedToken);
          useStore()?.setUsersHint(loginHint);
          next();
        }
      else if (to.meta.auth && !isLoggedIn()){
        const redirect = `${window.location.protocol}//${window.location.host}`;
        window.location.href =  `${env.VUE_APP_MAIN_PAGE_URL}sso-login?redirect-to=${redirect}`;
      }
      else {
        next();
      }
    });

export default router;
