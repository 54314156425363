<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-v-48ba664c=""
  >
    <path
      d="M5 15C2.79 15 1 16.79 1 19C1 19.75 1.21 20.46 1.58 21.06C2.27 22.22 3.54 23 5 23C6.46 23 7.73 22.22 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15ZM6.97 18.67L4.84 20.64C4.7 20.77 4.51 20.84 4.33 20.84C4.14 20.84 3.95 20.77 3.8 20.62L2.81 19.63C2.52 19.34 2.52 18.86 2.81 18.57C3.1 18.28 3.58 18.28 3.87 18.57L4.35 19.05L5.95 17.57C6.25 17.29 6.73 17.31 7.01 17.61C7.29 17.91 7.27 18.39 6.97 18.67Z"
      :fill="color"
      data-v-48ba664c=""
    ></path>
    <path
      d="M21.5 12.5H19C17.9 12.5 17 13.4 17 14.5C17 15.6 17.9 16.5 19 16.5H21.5C21.78 16.5 22 16.28 22 16V13C22 12.72 21.78 12.5 21.5 12.5Z"
      :fill="color"
      data-v-48ba664c=""
    ></path>
    <path
      d="M16.5302 5.40016C16.8302 5.69016 16.5802 6.14016 16.1602 6.14016L7.88021 6.13016C7.40021 6.13016 7.16021 5.55016 7.50021 5.21016L9.25021 3.45016C10.7302 1.98016 13.1202 1.98016 14.6002 3.45016L16.4902 5.36016C16.5002 5.37016 16.5202 5.39016 16.5302 5.40016Z"
      :fill="color"
      data-v-48ba664c=""
    ></path>
    <path
      opacity="0.4"
      d="M21.8704 18.66C21.2604 20.72 19.5004 22 17.1004 22H10.6004C10.2104 22 9.96035 21.57 10.1204 21.21C10.4204 20.51 10.6104 19.72 10.6104 19C10.6104 15.97 8.14035 13.5 5.11035 13.5C4.35035 13.5 3.61035 13.66 2.93035 13.96C2.56035 14.12 2.11035 13.87 2.11035 13.47V12C2.11035 9.28 3.75035 7.38 6.30035 7.06C6.55035 7.02 6.82035 7 7.10035 7H17.1004C17.3604 7 17.6104 7.01 17.8504 7.05C19.8704 7.28 21.3304 8.51 21.8704 10.34C21.9704 10.67 21.7304 11 21.3904 11H19.1004C16.9304 11 15.2104 12.98 15.6804 15.23C16.0104 16.87 17.5304 18 19.2004 18H21.3904C21.7404 18 21.9704 18.34 21.8704 18.66Z"
      :fill="props.color"
      data-v-48ba664c=""
    ></path>
  </svg>
</template>

<script setup lang="ts">
const props = defineProps<{
  color?: string;
}>();
</script>

<style scoped></style>
